.blog {
  .blog-collections {
    @include shift-left(0.9);

    .row {
      @include row-flex;
      justify-content: space-between;
      .blog-main {
        @include size(8);
        @include size-xs(7.3);

        .portfolio-heder-text {
          @include size(8);
          @include size-lg(9);
          @include size-md(10);
          @include size-sm(12);
          padding-bottom: 5%;
          h1 {
            @include header-text;
          }
          p {
            @include p-text;
            a {
              text-decoration: underline;

            }
          }

        }

        .blog-item-photo {
          .item {
            position: relative;
            cursor: pointer;
          }
          .item img {
            width: 100%;
            vertical-align: top;
          }

          .item:after {
            position: absolute;
            opacity: 0;
            transition: all 0.3s;
            -webkit-transition: all 0.3s;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.4);
          }

          .item:before {
            @include photo-on-text;
            @include size(6.5);
            @include size-xxlg(9);
            @include size-lg(10);
            @include size-xs(11);
            @include size-xxs(12);
            position: absolute;
            content: attr(data-content);
            color: #fff;
            z-index: 1;
            bottom: 0;
            padding: 0 0 7% 10%;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            opacity: 1;
          }
          .item:hover:after, .image-slide:hover:before {
            opacity: 1;

          }

        }

        .blog-link-items {
          @include row-flex();
          justify-content: space-between;
          padding: 5% 0 15%;

          .items {
            @include col;
            @include size(5.5);
            @include size-sm(12);

            .item {
              @include title-h2;
              @include border-bottom-text;

              padding-top: 10%;
              padding-bottom: 5%;
              &:before {
                @include to($max_width: 320px) {
                  font-size: 14px;

                }
              }

            }

          }
        }
      }
    }
  }
}
