.logo-page-404{
  .logo-pos {
    position: absolute;
    left: 4.5%;
    top: 10%;
    height: 100%;
    @include to($max_width: 1920px) {
      left: 2.5%;
      top: -9%;;
    }
    @include to($max_width: 1440px) {
      left: 0.5%;
      top: 3%;
    }
    @include to($max_width: 1280px) {
      left: -0.5%;
      top: 1%;;
    }
    @include to($max_width: 1024px) {
      left: -4.5%;
    }

    @include to($max_width: 768px) {
      left: -0.5%;
      top: 2%;
    }

    @include to($max_width: 425px) {
      left: 3.5%;
      top: 4%;
    }
    @include to($max_width: 320px) {
      left: 3.5%;
      top: 4%;
    }
    .logo-404 {
      width: 235px;
      top: 0;
      left: 0;
      position: absolute;

      @include to($max_width: 768px) {
        width: 170px;
      }
      @include to($max_width: 480px) {
        width: 150px;
      }
      @include to($max_width: 375px) {
        width: 150px;
      }
      @include to($max_width: 320px) {
        width: 140px;
      }

    }
  }
}

.page-404 {
  @include row-flex;
  justify-content: space-between;

  .page-404-main {
    @include col;
    @include size(8);
    @include size-xs(9);
    @include size-xxs(9.2);
    @include shift-padding-left(1);
    @include shift-xs-padding-left(1.5);
    .page-404-text {
      @include size(8);
      @include size-sm(12);
      @include size-xs(12);


      margin: 10% 0 3% 0;

      @include to($max_width: 1920px) {
        margin: 15% 0 3% 0;
      }
      @include to($max_width: 1440px) {
        margin: 20% 0 3% 0;
      }
      @include to($max_width: 768px) {
        margin: 23% 0 3% 0;
      }
      @include to($max_width: 480px) {
        margin: 25% 0 3% 0;;
      }

      h1 {
        @include header-text;
      }
      p {
        @include p-text;
      }
    }

    .page-404-button {
      @include size(5);
      @include size-sm(8);
      @include size-xs(12);
      padding-top: 2%;
      .button {
        @include button();
        text-align: center;
        display: inline-block;
        padding: 5%;

      }
    }
  }
  .navbar {
    position: fixed;
    right: 18%;
    z-index: 1;

    @include to($max_width: 1440px) {
      right: 12%;
    }
    @include to($max_width: 480px) {
      right: 4%;
    }
    #navbar {
      @include navbar();
    }
  }
}
