.project-blog {

  .header {
    margin-bottom: 5%;
    @include shift-left(0.9);

    .row {
      @include row-flex;
      justify-content: space-between;

      //text

      .text-header {
        @include col;
        @include size(9);
        @include size-xs(8);
        @include size-xxs(7.2);

        .text {
          @include size(6);
          @include size-xxlg(7);
          @include size-xlg(9);
          @include size-xs(12);
        }

        h1 {
          @include header-text;
        }

        p {
          @include p-text;
        }
      }
    }
  }

  //TODO: PROJECT-MAIN-CONTENET

  .project-main-content {
    @include shift-left(0.9);
    @include size(7.4);
    @include size-xs(6.4);

    .project-text {
      padding: 10% 0;

      .item-task {

        h2 {
          position: relative;
          @include title-h2;
          padding-bottom: 2%;

        }
        h2:before {
          @include pseudo-dot-h2();
          top: -6%;
          left: -2%;
        }
        p {
          @include p-text;
        }
      }
    }

    .item-decision {
      padding-top: 10%;
      h2 {
        position: relative;
        @include title-h2;
        padding-bottom: 2%;

      }
      h2:before {
        @include pseudo-dot-h2();
        top: -6%;
        left: -2%;

      }

      p {
        @include p-text;

      }
    }

    .project-photo {
      padding: 10% 0 0;
      .item-img {
        padding-top: 3%;
      }
    }

    .project-photo-2 {
      @include row-flex();
      justify-content: space-between;
      padding: 10% 0 0;
      .item-img {
        @include col();
        @include size(5.9);

      }

    }

    .project-mini-photo {
      @include row-flex;
      justify-content: flex-start;
      padding: 10% 0 10%;

      .item-img {
        padding-bottom: 2%;

        @include col();
        @include size(3);
        @include size-xs(12);

      }
    }

    .project-description {
      padding: 10% 0 5%;
      @include row-flex;
      justify-content: space-between;

      .items {
        @include col;
        @include size(5);
        @include size-xs(12);
        .item {
          padding-bottom: 15%;
          .item-id {
            p {
              @include text-prof;
            }
          }

          .item-name {
            h3 {
              @include title-h2;
            }
          }
          button {
            @include button();
          }
        }
      }
    }

    .project-team-feedback {
      padding: 10% 0 5%;

      h2 {
        position: relative;
        @include title-h2;
        padding-bottom: 5%;

      }
      h2:before {
        @include pseudo-dot-h2();
        top: -6%;
        left: -2%;
      }
      .feedbacks {
        @include row-flex;
        justify-content: space-between;
        .items {
          @include col;
          @include size(5);
          @include size-xs(12);
          .item {
            @include border-bottom-text;
            padding-bottom: 5%;
            margin-bottom: 15%;

            .name {
              h3 {
                @include title-h2;
                padding-bottom: 2%;
              }
            }
            .profesion {
               p {
                 @include text-prof;

               }
            }
            .text {
               p {
                 padding-top: 5%;
                 @include p-text;
               }

            }
          }
        }
      }
    }

    //BTN

    .buttons-network {
      .items {
        @include row-flex();
        justify-content: space-between;

        .item {
          @include col();
          @include size(4);
          @include size-xs(12);
          padding: 5% 0;
          button {
            display: flex;
            justify-content: center;
            align-items: center;
            @include button();
            svg {
              margin-right: 5px;
              width: 1.2rem;
              height: 1.2rem;
            }
          }
          button:hover #facebook, button:hover #linkedin, button:hover #pinterest {
            transition: ease 0.3s;
            fill: #fff;
          }
        }
      }
    }
  }

  .project-main-photo {
    .photo {
      @include shift-left(0.9);
      @include size(10.2);
      margin-bottom: 5%;
    }
  }
}

.block-background {
  @include background-block();

  .wrapper {
    .title-projects {
      padding-top: 10%;
      @include shift-left(0.9);
      h2 {
        position: relative;
        @include title-h2;
        &:before {
          @include pseudo-dot-h2();
          top: -6%;
          left: -2%;
        }

      }
    }

    #slider-container {
      @include shift-left(0.8);

      .owl-nav {
        margin: 0;
      }
      .owl-carousel {
        padding-bottom: 5%;

        a {
          cursor: pointer;
        }
        .owl-stage {
          position: relative;
          height: 750px;
          padding-top: 30px;
          padding-left: 1.2%;

          @include to($max_width:1440px){
            height: 580px;
          }
          @include to($max_width:768px){
            height: 390px;
          }
          @include to($max_width:480px){
            height: 550px;
          }

        }
        .owl-item {
          .item {
            height: 732px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            width:100%;
            display: block;
            @include to($max_width:1440px){
              height: 534px;
            }
            @include to($max_width:768px){
              height: 340px;
            }
            @include to($max_width:480px){
              height: 494px;
            }
          }
        }

        .animated {
          -webkit-animation-duration: 2000ms;
          animation-duration: 2000ms;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }

        .owl-animated-out {
          z-index: 0;
        }

        .owl-animated-in {
          z-index: 1;

        }
        .fadeNext {
          -webkit-animation-name: fadeNext;
          animation-name: fadeNext;
        }
        .fadePrev {
          -webkit-animation-name: fadePrev;
          animation-name: fadePrev;
        }

        .bringOut {
          opacity: 1;
          //left: 0;
        }
        .bringIn {
          opacity: 1;
        }

        @keyframes bring {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 1;
          }
        }

        .owl-nav {
          padding-left: 1.2%;

          @include row-flex;
          justify-content: space-between;
          text-align: inherit;

          .owl-next, .owl-prev {
            @include col();
            @include size(3.2);
            @include size-md(3);
            @include size-sm(4);
            @include size-xs(5.5);
            @include size-xxs(6);
            position: relative;
            background: none;
            #left, #right {
              @include slider-botton-text;
              padding-left: 30px;

              @include to($max_width: 480px) {
                padding-left: 15px;
              }


              &:hover {
                color: #9E9E9E;
                transition: all 0.3s ease;
              }
              &:before {
                background-color: #363636;
                content: "";
                border-radius: 50%;
                width: 4px;
                height: 4px;
                display: inline-block;
                position: absolute;
                left: 0;
                top: 22%;
              }
              &:hover:before {
                background-color: #9E9E9E;
                transition: all 0.3s ease;
              }
            }
          }
        }



        .image-slide {
          width: 100%;
          vertical-align: top;
        }
        .image-slide:after {
          content: '';
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.4);
          position: absolute;
          opacity: 0;
          transition: all 0.3s;
          -webkit-transition: all 0.3s;
        }

        .image-slide:before {
          @include photo-on-text;
          @include size(5);
          @include size-xs(8);
          @include size-xxs(10);
          position: absolute;
          content: attr(data-content);
          color: #fff;
          z-index: 1;
          bottom: 0;
          padding: 0 0 7% 10%;
          box-sizing: border-box;
          opacity: 1;
          @include to($max_width: 480px) {
            padding: 0 0 10% 15%;
          }
          @include to($max_width: 320px) {
            padding: 0 0 10% 18%;
          }
        }
        .image-slide:hover:after, .image-slide:hover:before {
          opacity: 1;
        }
      }

      &:before {
        background-color: #F9F9F9;
        content: "";
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        padding-bottom: 75%;
        z-index: -50;
      }
    }
  }

}




