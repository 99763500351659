// Includes all styles
// Supports glob patterns imports

//Settings:
// No settings for now. The idea behind this section is to create  a variables for

//Core:
@import "variables";

//Mixins:
@import "mixins/_backround-block.scss";
@import "mixins/_buttons.scss";
@import "mixins/_font-face.scss";
@import "mixins/_font-style.scss";

//Helpers:
@import "../node_modules/normalize.css/normalize";

//Components | Blocks
// the components are adding from bottom to the top of complexity.
// first - the primitive blocks (modules folder)
// last - pages - the specific styles for the set of building blocks


@import "modules/_smart-grid.scss";
@import "components/_dots-animate.scss";
@import "components/_fonts.scss";
@import "components/_navbar-footer.scss";
@import "components/_navbar.scss";
@import "components/_owl.scss";
@import "partials/_body.scss";
@import "partials/_debag.scss";
@import "partials/_footer.scss";
@import "partials/_wrapper.scss";
@import "pages/_404.scss";
@import "pages/_article.scss";
@import "pages/_blog.scss";
@import "pages/_index.scss";
@import "pages/_main-page.scss";
@import "pages/_portfolio.scss";
@import "pages/_project.scss";
@import "pages/_subscribe.scss";
@import "pages/_team-page.scss";
@import "pages/_thank-you.scss";

