.wrapper {
  position: relative;
  @include wrapper-full();
  @include reset();
  @include shift-left(2);
  @include shift-xlg-left(1);
  @include shift-md-left(0.5);
  @include shift-xs-left(-0.2);
  height: auto;

  .navbar {
    opacity: 1;
    transition: all 0.3s ease-out;
    
    &.fade-out {
      opacity: 0;
    }
    .navigation {
      @include navbar();
    }
    .current-menu-item {
      @include nav-active()

    }
    .en-link {
      @include nav-en-link();
    }
    #navbar {
      opacity: 0;
      pointer-events:none;
      transition: all 0.3s ease-in;
    }

  }

  .nav-next-prev {
    position: fixed;
    bottom: 50px;
    z-index: 1;

    ul {

      li {
        margin-bottom: 25px;

        .next, .prev {
          @include nav-next-prev;
          transition: all 0.3s ease-in;
          cursor: pointer;

        }
        .counter {
          @include counter();
        }
      }
    }
  }

  .navigation-menu {
    position: fixed;
    right: 18%;
    z-index: 1;

    @include to($max_width: 1440px) {
      right: 12.1%;
    }
    @include to($max_width: 1280px) {
      right: 11%;
    }
    @include to($max_width: 480px) {
      right: 4%;
    }
  }

  .logo-an {
    .logo-pos {
      .logo-part-1 {
        position: absolute;
        width: 40px;
        height: 38px;
        left:0;

        @include to($max_width: 768px) {
          width: 30px;
          height: 28px;
        }
        @include to($max_width: 480px) {
          width: 17px;
          height: 16px;
        }

      }
      .logo-animate {
        // display: none;
        position: absolute;
        background: #363636;
        top: calc(38px / 2);
        left: 19px;
        width: 21px;
        transition: all 0.5s ease-out;

        @include to($max_width: 768px) {
          top: calc(28px / 2);
          left: 14px;
          width: 16px;
        }
        @include to($max_width: 480px) {
          top: calc(16px / 2);
          left: 7px;
          width: 10px;
        }

        &.long{
          height: 100vh!important;
        }


      }

      .logo-animate:before {
        background: url(../images/svg/logo-part-618.svg) no-repeat;
        content: "";
        background-size: 24px 50px;
        position: absolute;
        background-position: 0 0;
        border: none;
        height: 50px;
        padding-right: 30px;
        top: 105%;
        left: 0;
        @include to($max_width: 768px) {
          background-size: 16px 42px;
          height: 40px;
        }
        @include to($max_width: 480px) {
          background-size: 9px 23px;
          top: 102%;
          height: 40px;
        }
      }
    }
  }
}

.buttons-network {
  padding: 5% 0;

  .row {
    @include row-flex();
    justify-content: space-between;

    .item {
      @include col();
      @include size(4);
      @include size-sm(12);
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        @include button();
        @include to($max_width: 768px) {
          margin-bottom: 20px;
        }
        svg {
          margin-right: 5px;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
      button:hover #facebook, button:hover #linkedin, button:hover #pinterest {
        transition: ease 0.3s;
        fill: #fff;
      }
    }
  }
}