article {
  @include shift-left(0.9);

  header {
    margin-bottom: 5%;
    .row {
      @include row-flex;

      .entry-meta {
        margin-bottom: 7%;

        @include col;
        @include size(6);
        @include size-xlg(7);
        @include size-lg(8);
        @include size-xs(7);
        h1 {
          @include header-text;
        }
        p {
          @include p-text;
        }
      }
    }


    //main-photo
    .size-large {
      //@include col;
      @include size(11);
      @include size-xs(12);

      //@include shift-padding-right(1);
      //@include shift-xs-padding-right(0);
      //width: 100%;

    }
  }

  //main-content
  .entry-content {
    @include size(7.4);
    @include size-xs(12);
    padding-bottom: 10%;

    .entry-content-text {
      @include size-xs(8);
      @include size-xxs(6.5);
      h2 {
        @include title-h2;
        position: relative;
        padding-bottom: 3%;
        &:before {
          display: block;
          position: absolute;
          content: '';
          background-color: #363636;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          top: 2%;
          left: -2%;
          @media screen and (max-width: 768px) {
            left: -3%;
          }
          @media screen and (max-width: 480px) {
            width: 4px;
            height: 4px;
          }
        }
      }
      p {
        @include p-text;
      }

      ul {
        li {
          @include p-text;
          list-style-type: disc;
          margin: 2% 10%;
        }
      }
      //blockquote--1
      .blockquote {
        margin: 5% 0;
        background-color: #F9F9F9;
        padding: 5% 15%;
        color: #363636;
        font-weight: 300;
        line-height: 47px;

        p {
          @include p-text;

          font-style: italic;
        }
        .resource {
          padding-top: 2%;
          font-style: normal;
          font-size: 1.5rem;

        }
      }

      //blockquote--2
      blockquote {
        .blockquote-background {
          background-color: #F9F9F9;
        }
        .quote-1 {
          margin-bottom: 15%;
          padding: 5% 10%;

          & :last-child {
            font-weight: 400;
            font-size: 19px;
            padding-top: 5px;
            font-style: normal;

            @include to($max_width: 480px) {
              font-size: 16px;
            }
          }

          p {
            @include p-text;
            font-style: italic;
            font-weight: lighter;
          }
        }

        .quote-2 {
          border-left: 4px solid #363636;
          padding-left: 2%;
          p {
            @include p-text;
            font-weight: bold;
          }
          & :last-child {
            font-weight: 400;
            font-size: 19px;
            padding-top: 5px;
            @include to($max_width: 480px) {
              font-size: 16px;
            }
          }
        }

      }
    }

    .gallery {
      padding: 10% 0;

      figure {
        @include row-flex();
        justify-content: space-between;
        & :last-child {
          @include to($max_width: 480px) {
            padding-top: 3%;
          }
        }
        a {
          display: inline-block;
          cursor: default;
        }
        .size-large {
          @include col;
          @include size(12);
          padding-bottom: 3%;

        }
        .size-medium {
          @include col;
          @include size(5.9);
          @include size-xs(12);

        }
      }
    }
  }
  .entry-footer {
    padding: 10% 0;

    @include size(7.4);
    @include size-xs(12);

    h2 {
      @include title-h2;
      position: relative;
      padding-bottom: 5%;
      &:before {
        @include pseudo-dot-h2();
        top: -7%;
        left: -2%;
      }

    }
    .size-large {
      position: relative;
      cursor: pointer;
      z-index: 0;
      img {
        width: 100%;
        vertical-align: top;
      }

      &:before {
        content: attr(data-content);
        @include photo-on-text;
        @include size(7);
        @include size-xlg(8);
        @include size-xs(7);
        position: absolute;
        color: #fff;
        z-index: 1;
        bottom: 0;
        padding: 0 0 7% 10%;
        box-sizing: border-box;
        opacity: 1;
      }

    }

    .size-large:after {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      opacity: 0;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
    }

    .size-large:hover:after, .size-large:hover:before {
      opacity: 1;
    }
    .entry-content-text {
      @include size-xs(8);
      @include size-xxs(6.5);
      .row {
        margin-top: 10%;
        @include row-flex;
        justify-content: space-between;
        .col {
          align-self: flex-start;
          @include col();
          @include size(5.5);
          @include size-xs(12);
          @include title-h2;
          @include border-bottom-text;
          padding-bottom: 3%;
          @include to($max_width: 480px) {
            margin-bottom: 5%;
          }
        }
      }
    }

  }
}

.container-background {
  @include background-block();
}
