#dotsAnimate {
  position: relative;
  overflow:hidden;
  max-width: 2560px;
  height: 150px;
  opacity: 0;
  transition: all 0.5s ease;
}

.box {
  background-color: #363636;
  position: absolute;
  border-radius: 50%;
  top: 0;
  content:'';
  width: 8px;
  height: 8px;

}


@media screen and (max-width: 768px) {
  #dotsAnimate {
    position: relative;
    width: 100%;
    height: 100px;
  }
}

@media screen and (max-width: 420px) {
  #dotsAnimate {
    position: relative;
    width: 100%;
    height: 70px;
  }
  .box {
    width: 6px;
    height: 6px;
  }
}
