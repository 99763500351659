.main-page {
  position: relative;
  .header {
    @include shift-left(0.9);
    position: relative;
    padding-bottom: 50%;

    .row {
      @include row-flex();
      justify-content: space-between;

      .header-text {
        @include col;
        @include size(9);
        @include size-xs(8);
        @include size-xxs(7.5);

        .text {
          @include size(6);
          @include size-xxlg(7);
          @include size-xlg(8);
          @include size-lg(9);
          @include size-xs(11.5);

          h1 {
            white-space: nowrap;
            @include header-text();
          }
          p {
            @include p-text;
          }
        }
      }


    }
  }

  #slider-container {
    @include shift-left(0.8);
    margin-bottom: 15%;


    .owl-carousel {
      a {
        cursor: pointer;
      }
      .owl-stage {
        position: relative;
        height: 750px;
        padding-top: 30px;
        padding-left: 1.2%;

        @include to($max_width:1440px){
          height: 580px;
        }
        @include to($max_width:768px){
          height: 390px;
        }
        @include to($max_width:480px){
          height: 550px;
        }

      }
      .owl-item {
        .item {
          height: 1000px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;

          width:100%;
          display: block;
          @include to($max_width:1920px){
            height: 732px;
          }
          @include to($max_width:1440px){
            height: 534px;
          }
          @include to($max_width:768px){
            height: 340px;
          }
          @include to($max_width:480px){
            height: 494px;
          }
        }
      }

      .animated {
        -webkit-animation-duration: 2000ms;
        animation-duration: 2000ms;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }

      .owl-animated-out {
        z-index: 0;
      }

      .owl-animated-in {
        z-index: 1;

      }
      .fadeNext {
        -webkit-animation-name: fadeNext;
        animation-name: fadeNext;
      }
      .fadePrev {
        -webkit-animation-name: fadePrev;
        animation-name: fadePrev;
      }

      .bringOut {
        opacity: 1;
        //left: 0;
      }
      .bringIn {
        opacity: 1;
      }

      @keyframes bring {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 1;
        }
      }
      @keyframes fadeNext {
        0% {
          opacity: 1;
          -moz-box-shadow: none;
          box-shadow: none;
          transform: scale(1) translateX(103%);
        }
        20% {
          opacity: 1;
          -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
          transform: scale(1.05) translateX(100%);
        }
        60% {
          opacity: 1;
          -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
          transform: scale(1.02) translateX(2%);
        }
        80% {
          -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
          transform: scale(1.02) translateX(0.5%)
        }
        100% {
          opacity: 1;
          -moz-box-shadow: none;
          box-shadow: none;
          transform: scale(1) translateX(0);
        }
      }


      @keyframes fadePrev {
        0% {
          opacity: 1;
          -moz-box-shadow: none;
          box-shadow: none;
          transform: scale(1) translateX(0);
        }
        20% {
          opacity: 1;
          -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
          transform: scale(1.01) translateX(1%)
        }
        60% {
          opacity: 1;
          -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
          transform: scale(1.02) translateX(102.5%);

        }
        80% {
          opacity: 1;
          -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
          transform: scale(1.02) translateX(103%);

        }

        100% {
          opacity: 1;
          -moz-box-shadow: none;
          box-shadow: none;
          transform: scale(1) translateX(calc(100% + 30px));
        }
      }



      .owl-nav {
        padding-left: 1.2%;

        @include row-flex;
        justify-content: space-between;
        text-align: inherit;

        .owl-next, .owl-prev {
          @include col();
          @include size(3.2);
          @include size-md(3);
          @include size-sm(4);
          @include size-xs(5.5);
          @include size-xxs(6);
          position: relative;
          background: none;
          #left, #right {
            @include slider-botton-text;
            padding-left: 30px;

            @include to($max_width: 480px) {
              padding-left: 15px;
            }


            &:hover {
              color: #9E9E9E;
              transition: all 0.3s ease;
            }
            &:before {
              background-color: #363636;
              content: "";
              border-radius: 50%;
              width: 4px;
              height: 4px;
              display: inline-block;
              position: absolute;
              left: 0;
              top: 22%;
            }
            &:hover:before {
              background-color: #9E9E9E;
              transition: all 0.3s ease;
            }
          }
        }
      }



      .image-slide {
        width: 100%;
        vertical-align: top;
      }
      .image-slide:after {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        opacity: 0;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
      }

      .image-slide:before {
        @include photo-on-text;
        @include size(5);
        @include size-xs(8);
        @include size-xxs(10);
        position: absolute;
        content: attr(data-content);
        color: #fff;
        z-index: 1;
        bottom: 0;
        padding: 0 0 7% 10%;
        box-sizing: border-box;
        opacity: 1;
        @include to($max_width: 480px) {
          padding: 0 0 10% 15%;
        }
        @include to($max_width: 320px) {
          padding: 0 0 10% 18%;
        }
      }
      .image-slide:hover:after, .image-slide:hover:before {
        opacity: 1;
      }
    }
  }

  .main-article-collections {
    @include shift-padding-left(0.9);

    .article-title {
      @include size(4);
      @include size-xlg(5);
      @include size-lg(5);
      @include size-md(6);
      @include header-text();
      margin-bottom: 0;
    }

    .main-article {
      @include col;
      @include size(7.8);
      @include size-xs(6.8);
      min-height: 100vh;
      padding-bottom: 10%;

      .columns {
        //@include row-float;
        //display: block;

        .column{
          float: left;
          @include to($max_width: 480px) {
            float: none;
            margin-left: 10px;
          }
        }
        .column:nth-child(odd) {
          margin-left: 0;
        }
        .column:nth-child(even) {
          //float: right;
          margin-left: 10%;
          
          @include to($max_width: 480px) {
            margin-left: 0;
          }
        }
        .column:last-child {
          float: none;
          clear: both;
        }
        //.column:nth-child(1) {
        //    order: 1;
        //}
        //.column:nth-child(2) {
        //    order: 4;
        //}
        //.column:nth-child(3) {
        //    order: 2;
        //}
        //.column:nth-child(4) {
        //    order: 5;
        //}
        //.column:nth-child(5) {
        //    order: 3;
        //}
        .column {
          @include size(5.4);
          @include size-xs(12);
          margin-bottom: 0;
          padding-top: 10%;
          padding-bottom: 5%;
          @include border-bottom-text;

          h2 {
            @include title-h2();
            position: relative;
            padding-bottom: 5%;
          }

          h2:before {
            @include pseudo-dot-h2()
          }
          p {
            @include p-text;
          }
        }
      }
    }
  }
}



