@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i&subset=cyrillic,cyrillic-ext,latin-ext');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;

}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}



@media screen and (max-width: 1280px) {
  html, body {
    font-size: 12px;
  }

}

@media screen and (max-width: 768px) {
  html, body {
    font-size: 12px;

  }
}

@media screen and (max-width: 420px) {
  html, body {
    font-size: 8px;

  }
}
