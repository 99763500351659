$columns: 12;
$atom: (100% / $columns);

$break_xxlg: 1920px;
$break_xlg: 1440px;
$break_lg: 1280px;
$break_md: 992px;
$break_sm: 768px;
$break_xs: 480px;
$break_xxs: 320px;

$offset: 2.25%;
$offset_xxlg: $offset;
$offset_xlg: $offset;
$offset_lg: $offset;
$offset_md: $offset;
$offset_sm: $offset;
$offset_xs: $offset;
$offset_xxs: $offset;

$offset_one_side: ($offset / 2);
$offset_xxlg_one_side: ($offset_xxlg / 2);
$offset_xlg_one_side: ($offset_xlg / 2);
$offset_lg_one_side: ($offset_lg / 2);
$offset_md_one_side: ($offset_md / 2);
$offset_sm_one_side: ($offset_sm / 2);
$offset_xs_one_side: ($offset_xs / 2);
$offset_xxs_one_side: ($offset_xxs / 2);

$fields: 15px;
$fields_xxs: 10px;

@mixin wrapper-full(){
  padding-left: $fields;
  padding-right: $fields;

  @include xxs-block(){
    padding-left: $fields_xxs;
    padding-right: $fields_xxs;
  }
}

@mixin row-offsets(){
    margin-left: ($offset_one_side * -1);
    margin-right: ($offset_one_side * -1);

    @include xxlg-block(){
        margin-left: ($offset_xxlg_one_side * -1);
        margin-right: ($offset_xxlg_one_side * -1);
    }

    @include xlg-block(){
        margin-left: ($offset_xlg_one_side * -1);
        margin-right: ($offset_xlg_one_side * -1);
    }

    @include lg-block(){
        margin-left: ($offset_lg_one_side * -1);
        margin-right: ($offset_lg_one_side * -1);
    }

    @include md-block(){
        margin-left: ($offset_md_one_side * -1);
        margin-right: ($offset_md_one_side * -1);
    }

    @include sm-block(){
        margin-left: ($offset_sm_one_side * -1);
        margin-right: ($offset_sm_one_side * -1);
    }

    @include xs-block(){
        margin-left: ($offset_xs_one_side * -1);
        margin-right: ($offset_xs_one_side * -1);
    }

    @include xxs-block(){
        margin-left: ($offset_xxs_one_side * -1);
        margin-right: ($offset_xxs_one_side * -1);
    }
}

@mixin col-offsets($type){
    #{$type}-left: $offset_one_side;
    #{$type}-right: $offset_one_side;

    @include xxlg-block(){
        #{$type}-left: $offset_xxlg_one_side;
        #{$type}-right: $offset_xxlg_one_side;
    }

    @include xlg-block(){
        #{$type}-left: $offset_xlg_one_side;
        #{$type}-right: $offset_xlg_one_side;
    }

    @include lg-block(){
        #{$type}-left: $offset_lg_one_side;
        #{$type}-right: $offset_lg_one_side;
    }

    @include md-block(){
        #{$type}-left: $offset_md_one_side;
        #{$type}-right: $offset_md_one_side;
    }

    @include sm-block(){
        #{$type}-left: $offset_sm_one_side;
        #{$type}-right: $offset_sm_one_side;
    }

    @include xs-block(){
        #{$type}-left: $offset_xs_one_side;
        #{$type}-right: $offset_xs_one_side;
    }

    @include xxs-block(){
        #{$type}-left: $offset_xxs_one_side;
        #{$type}-right: $offset_xxs_one_side;
    }
}

@mixin wrapper(){
    max-width: 1600px;
    margin: 0 auto;
    @include wrapper-full();
}

@mixin row-flex(){
    display: flex;
    flex-wrap: wrap;
    @include row-offsets();
}

@mixin row-float(){
    @include row-offsets();
    @include clearfix();
}

@mixin row-ib(){
    @include row-offsets();
}

@mixin col(){
    box-sizing: border-box;
    word-wrap: break-word;
    @include col-offsets(margin);
}

@mixin col-float-left(){
    float: left;

}
@mixin col-float-right(){
  float: right;
;
}

@mixin col-ib(){
    display: inline-block;
    vertical-align: top;
}

@mixin col-padding(){
    word-wrap: break-word;
    @include col-offsets(padding);
}

@mixin size($n){
    $val: #{calc(100% / #{$columns} * #{$n} - #{$offset})};
    width: $val;
}

@mixin size-xxlg($n){
    $val: #{calc(100% / #{$columns} * #{$n} - #{$offset_xxlg})};
    @include xxlg(width, $val);
}

@mixin size-xlg($n){
    $val: #{calc(100% / #{$columns} * #{$n} - #{$offset_xlg})};
    @include xlg(width, $val);
}

@mixin size-lg($n){
    $val: #{calc(100% / #{$columns} * #{$n} - #{$offset_lg})};
    @include lg(width, $val);
}

@mixin size-md($n){
    $val: #{calc(100% / #{$columns} * #{$n} - #{$offset_md})};
    @include md(width, $val);
}

@mixin size-sm($n){
    $val: #{calc(100% / #{$columns} * #{$n} - #{$offset_sm})};
    @include sm(width, $val);
}

@mixin size-xs($n){
    $val: #{calc(100% / #{$columns} * #{$n} - #{$offset_xs})};
    @include xs(width, $val);
}

@mixin size-xxs($n){
    $val: #{calc(100% / #{$columns} * #{$n} - #{$offset_xxs})};
    @include xxs(width, $val);
}

@mixin shift($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_one_side})};
    margin-left: $val;
    margin-right: $val;
}

@mixin shift-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_one_side})};
    margin-left: $val;
}

@mixin shift-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_one_side})};
    margin-right: $val;
}

@mixin shift-padding($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_one_side})};
    padding-left: $val;
    padding-right: $val;
}

@mixin shift-padding-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_one_side})};
    padding-left: $val;
}

@mixin shift-padding-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_one_side})};
    padding-right: $val;
}

@mixin shift-xxlg($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xxlg_one_side})};
    @include xxlg(margin-left, $val);
    @include xxlg(margin-right, $val);
}

@mixin shift-xxlg-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xxlg_one_side})};
    @include xxlg(margin-left, $val);
}

@mixin shift-xxlg-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xxlg_one_side})};
    @include xxlg(margin-right, $val);
}

@mixin shift-xxlg-padding($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xxlg_one_side})};
    @include xxlg(padding-left, $val);
    @include xxlg(padding-right, $val);
}

@mixin shift-xxlg-padding-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xxlg_one_side})};
    @include xxlg(padding-left, $val);
}

@mixin shift-xxlg-padding-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xxlg_one_side})};
    @include xxlg(padding-right, $val);
}

@mixin shift-xlg($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xlg_one_side})};
    @include xlg(margin-left, $val);
    @include xlg(margin-right, $val);
}

@mixin shift-xlg-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xlg_one_side})};
    @include xlg(margin-left, $val);
}

@mixin shift-xlg-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xlg_one_side})};
    @include xlg(margin-right, $val);
}

@mixin shift-xlg-padding($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xlg_one_side})};
    @include xlg(padding-left, $val);
    @include xlg(padding-right, $val);
}

@mixin shift-xlg-padding-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xlg_one_side})};
    @include xlg(padding-left, $val);
}

@mixin shift-xlg-padding-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xlg_one_side})};
    @include xlg(padding-right, $val);
}

@mixin shift-lg($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_lg_one_side})};
    @include lg(margin-left, $val);
    @include lg(margin-right, $val);
}

@mixin shift-lg-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_lg_one_side})};
    @include lg(margin-left, $val);
}

@mixin shift-lg-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_lg_one_side})};
    @include lg(margin-right, $val);
}

@mixin shift-lg-padding($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_lg_one_side})};
    @include lg(padding-left, $val);
    @include lg(padding-right, $val);
}

@mixin shift-lg-padding-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_lg_one_side})};
    @include lg(padding-left, $val);
}

@mixin shift-lg-padding-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_lg_one_side})};
    @include lg(padding-right, $val);
}

@mixin shift-md($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_md_one_side})};
    @include md(margin-left, $val);
    @include md(margin-right, $val);
}

@mixin shift-md-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_md_one_side})};
    @include md(margin-left, $val);
}

@mixin shift-md-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_md_one_side})};
    @include md(margin-right, $val);
}

@mixin shift-md-padding($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_md_one_side})};
    @include md(padding-left, $val);
    @include md(padding-right, $val);
}

@mixin shift-md-padding-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_md_one_side})};
    @include md(padding-left, $val);
}

@mixin shift-md-padding-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_md_one_side})};
    @include md(padding-right, $val);
}

@mixin shift-sm($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_sm_one_side})};
    @include sm(margin-left, $val);
    @include sm(margin-right, $val);
}

@mixin shift-sm-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_sm_one_side})};
    @include sm(margin-left, $val);
}

@mixin shift-sm-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_sm_one_side})};
    @include sm(margin-right, $val);
}

@mixin shift-sm-padding($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_sm_one_side})};
    @include sm(padding-left, $val);
    @include sm(padding-right, $val);
}

@mixin shift-sm-padding-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_sm_one_side})};
    @include sm(padding-left, $val);
}

@mixin shift-sm-padding-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_sm_one_side})};
    @include sm(padding-right, $val);
}

@mixin shift-xs($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xs_one_side})};
    @include xs(margin-left, $val);
    @include xs(margin-right, $val);
}

@mixin shift-xs-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xs_one_side})};
    @include xs(margin-left, $val);
}

@mixin shift-xs-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xs_one_side})};
    @include xs(margin-right, $val);
}

@mixin shift-xs-padding($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xs_one_side})};
    @include xs(padding-left, $val);
    @include xs(padding-right, $val);
}

@mixin shift-xs-padding-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xs_one_side})};
    @include xs(padding-left, $val);
}

@mixin shift-xs-padding-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xs_one_side})};
    @include xs(padding-right, $val);
}

@mixin shift-xxs($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xxs_one_side})};
    @include xxs(margin-left, $val);
    @include xxs(margin-right, $val);
}

@mixin shift-xxs-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xxs_one_side})};
    @include xxs(margin-left, $val);
}

@mixin shift-xxs-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xxs_one_side})};
    @include xxs(margin-right, $val);
}

@mixin shift-xxs-padding($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xxs_one_side})};
    @include xxs(padding-left, $val);
    @include xxs(padding-right, $val);
}

@mixin shift-xxs-padding-left($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xxs_one_side})};
    @include xxs(padding-left, $val);
}

@mixin shift-xxs-padding-right($n){
    $val: #{calc(100% / #{$columns} * #{$n} + #{$offset_xxs_one_side})};
    @include xxs(padding-right, $val);
}

@mixin xxlg($name, $value){
    @media screen and (max-width: $break_xxlg){
        #{$name}: $value;
    }
}

@mixin xxlg-block(){
    @media screen and (max-width: $break_xxlg){
        @content;
    }
}

@mixin xlg($name, $value){
    @media screen and (max-width: $break_xlg){
        #{$name}: $value;
    }
}

@mixin xlg-block(){
    @media screen and (max-width: $break_xlg){
        @content;
    }
}

@mixin lg($name, $value){
    @media screen and (max-width: $break_lg){
        #{$name}: $value;
    }
}

@mixin lg-block(){
    @media screen and (max-width: $break_lg){
        @content;
    }
}

@mixin md($name, $value){
    @media screen and (max-width: $break_md){
        #{$name}: $value;
    }
}

@mixin md-block(){
    @media screen and (max-width: $break_md){
        @content;
    }
}

@mixin sm($name, $value){
    @media screen and (max-width: $break_sm){
        #{$name}: $value;
    }
}

@mixin sm-block(){
    @media screen and (max-width: $break_sm){
        @content;
    }
}

@mixin xs($name, $value){
    @media screen and (max-width: $break_xs){
        #{$name}: $value;
    }
}

@mixin xs-block(){
    @media screen and (max-width: $break_xs){
        @content;
    }
}

@mixin xxs($name, $value){
    @media screen and (max-width: $break_xxs){
        #{$name}: $value;
    }
}

@mixin xxs-block(){
    @media screen and (max-width: $break_xxs){
        @content;
    }
}

@mixin from($min_width){
    @media screen and (min-width: $min_width){
        @content;
    }
}

@mixin to($max_width){
    @media screen and (max-width: $max_width){
        @content;
    }
}

@mixin from-to($min_width, $max_width){
    @media screen and (min-width: $min_width) and (max-width: $max_width){
        @content;
    }
}

@mixin reset(){
    body,div,dl,dt,dd,ul,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,input,textarea,p,blockquote,th,td{
        margin: 0;
        padding: 0;
    }
    table{
        border-collapse: collapse;
        border-spacing: 0;
    }
    fieldset,img,abbr{
        border: 0;
    }
    address,caption,cite,code,dfn,em,strong,th,var{
        font-style: normal;
        font-weight: normal;
    }
    ul li{
        list-style: none;
    }
    caption,th{
        text-align: left;
    }
    h1,h2,h3,h4,h5,h6{
        font-size: 100%;
        font-weight: normal;
    }
    sup{
        vertical-align: text-top;
    }
    sub{
        vertical-align: text-bottom;
    }
    input,textarea,select{
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }
    legend{
        color: #000;
    }
    article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main{
        display: block;
    }
    img{
        max-width: 100%;
        height: auto;
    }

}

@mixin debug($background, $outline){
    height: 100vh;
    position: fixed;
    width: 100%;
    z-index: 1000;

    > div{
        height: inherit;
        @include wrapper();

        > div{
            height: inherit;
            @include row-flex();

            > div{
                @include col();
                @include size(1);
                @include size-xxlg(1);
                @include size-xlg(1);
                @include size-lg(1);
                @include size-md(1);
                @include size-sm(1);
                @include size-xs(1);
                @include size-xxs(1);
                height: inherit;
                outline: $outline;
                background: $background;
            }
        }
    }
}

@mixin clearfix(){
    &::after{
        display: block;
        content: "";
        clear: both;
    }
}
