.footer {
  //position: relative;
  overflow: hidden;
  height: 100vh;
  background-color: #363636;
  z-index: 1000;
  min-height: 570px;
  .wrapper{
    height: 100%;
  }

  .logo-footer {
    .logo-footer-pos {
      #logo-footer-aside{
        content: "";
        position: absolute;
        height: 576px;
        background-color: #ffffff;
        top: calc(38px / 2);
        left: 19px;
        width: 21px;

        @include to($max_width: 768px) {
          top: calc(28px / 2);
          left: 14px;
          width: 16px;
        }
        @include to($max_width: 480px) {
          top: calc(16px / 2);
          left: 7px;
          width: 10px;
        }

      }
  
      .logo-part-2 {
        position: absolute;
        width: 40px;
        height: 38px;

        @include to($max_width: 768px) {
          width: 30px;
          height: 28px;
        }
        @include to($max_width: 480px) {
          width: 17px;
          height: 16px;
        }

      }
    }
  }

  .footer-head {
    @include shift-left(0.9);
    padding: 10% 0 5%;
    height: 85%;
    position: relative;

     
     .row {
       @include row-flex;
       justify-content: space-between;


       .footer-items-collection {
         @include col();
         @include size(7.4);

         .footer-text {
           @include size-xxs(12);

           h2.footer-title {
             @include header-text;
             color: #fff;
           }
         }
        .footer-item-columns {

          @include row-flex;
          justify-content: space-between;

          .items {
            @include col();
            @include size(5);
            @include size-xs(12);
            .item {
              padding-bottom: 8%;
              h2 {
                @include title-h2;
                padding-bottom: 3%;
                color: #fff
              }
              a {
                @include p-text;
                color: #fff;
                &:hover {
                  color: #9E9E9E;
                  transition: all 0.3s ease;
                }
                &.map {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
       .footer-nav {
         position: absolute;
         z-index: 100;

         right: 22%;
         @include to($max_width: 1440px) {
           right: 13.4%;
         }
         @include to($max_width: 1280px) {
           right: 12.2%;
         }
         @include to($max_width: 768px) {
           right: 11.6%;
         }
         @include to($max_width: 480px) {
           right: 4%;
         }
         #navbar {

           .navigation {
             @include navbar-footer();
           }
           .en-link {
             @include en-link();
           }
         }
       }
    }
  }

  //TODO: Footer logo down
  .footer-logo-down {
    margin-left: 19px;
    
    @include to($max_width: 768px) {
      margin-left: 14px;
    }
    @include to($max_width: 480px) {
      margin-left: 7px;
    }
    position: relative;
  
    .logoline-618 {
      position: relative;
      
      @include size(7.4);
      @include size-xlg(7.8);
      @include size-xxs(8);
      
      height: 21px;
      background-color: #fff;

      @include to($max_width: 768px) {
        height: 16px;
      }
      @include to($max_width: 480px) {
        height: 10px;
      }

    }

    .logoline-618:before {
      background: url(../images/svg/618.svg) no-repeat;
      content: "";
      // background-size: 46px 40px;
      background-size: 50px 21px;
      position: absolute;
      // background-position: 0px -8px;
      border: none;
      height: 21px;
      padding-right: 50px;
      right: -60px;
      
      @include to($max_width: 768px) {
        background-size: 50px 16px;        
        height: 16px;
      }
      @include to($max_width: 480px) {
      background-size: 50px 10px;
      height: 10px;
      }
    }

  }

  //TODO:footer links
  .footer-link-collections {
    display: block;
    position: relative;
    background-color: #363636;
    // height: 15%;

    .footer-links {
      @include col;
      @include size(7.4);
      @include size-lg(8.2);
      @include size-xxs(8);


      padding: 2.5% 0 5% 8.33%;

      .links {
        @include row-flex;
        justify-content: space-between;

        .link {
          position: relative;
          @include col();
          @include size(2);
          @include size-lg(4);
          @include size-md(4);
          @include size-sm(4);
          @include size-xs(5);
          @include p-text;
          color: #fff;
          &:hover {
            transition: all 0.3s ease;
            color: #9E9E9E;
          }
        }

        .link:before {
          position: absolute;
          background-color: #fff;
          content: "";
          border-radius: 50%;
          width: 8px;
          height: 8px;
          display: inline-block;
          left: -12px;
          top: 2px;
        }
        .link:hover:before {
          background-color: #9E9E9E;
          transition: all 0.3s ease;
        }
      }
    }
  }
}