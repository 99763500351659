.logo-subscribe {
  .logo-pos {
    position: absolute;
    left: 2.5%;
    height: 100%;
    @include to($max_width: 1280px) {
      left: .8%;
    }
    .logo-part-1 {
      width: 40px;
      left: 37px;
      position: absolute;

    }
    .logo-animate {
      position: absolute;
      background: #363636;
      top: 35px;
      left: 55px;
      width: 22px;
      height: 100%;

    }
    .logo-part-618 {
      position: absolute;
      bottom: -105px;
      left: 55px;
      width: 22px;

    }
  }
}

.subscribe {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .subscribe-main {
    @include col;
    @include size(8);
    @include shift-padding-left(0.9);
    .subscribe-header-text {
      @include size(8);
      @include size-sm(12);
      @include size-xs(12);

      padding-bottom: 3%;

      h1 {
        @include header-text;


      }
      p {
        @include p-text;
      }
    }

    #group-input {
      @include size(8);
      @include size-sm(12);
      @include size-xs(12);

      .group {
        position: relative;
        padding: 5% 0 10%;

        input {
          font-size: 1.5rem;
          padding: 10px 10px 10px 5px;
          -webkit-appearance: none;
          display: block;
          color: #363636;
          border: none;
          width: 100%;
          border-radius: 0;
          border-bottom: 2px solid #363636;
          &:focus {
            outline: none;
          }
        }



        label {
          color: #363636;
          font-size: 1.5rem;
          font-weight: normal;
          position: absolute;
          pointer-events: none;
          left: 0;
          top: 25%;
          transition: all 0.2s ease;
        }



        input {
          &:focus ~ label, &.used ~ label {
            top: -1%;
            left: 0;
            color: #9E9E9E;
          }
        }

        .highlight {
          position: absolute;
          height: 60%;
          width: 100px;
          top: 25%;
          left: 0;
          pointer-events: none;
          opacity: 0.5;
        }


        input:focus ~ .highlight {
          animation: inputHighlighter 0.3s ease;
        }
      }


      //CHECKBOX
      .checkbox {
        padding-bottom: 15px;

        label {
          font-size: 1.1rem;
        }
        input[type="checkbox"] {
          position: absolute;
          z-index: -1;
          opacity: 0;
        }

        input[type="checkbox"] + label {
          position: relative;
          padding-left: 30px;
          padding-top: 3px;
          cursor: pointer;
        }

        input[type="checkbox"] + label:before,
        input[type="checkbox"] + label:after {
          content: ' ';
          position: absolute;
          box-sizing: border-box;
        }

        input[type="checkbox"] + label:before {
          top: 0;
          left: 0;
          height: 21px;
          width: 21px;
          border: 2px solid #363636;
        }

        input[type="checkbox"] + label:after {
          top: 0;
          left: 0;
          height: 21px;
          width: 21px;
        }

        input[type="checkbox"]:checked + label:after {
          background-color: #363636;
        }
      }
    }


    .subscribe-button {
      @include size(5);
      @include size-sm(8);
      @include size-xs(12);
      padding-top: 5%;
      .button {
        @include button();
        text-align: center;
        display: inline-block;
        padding: 5%;

      }
    }
  }
  .navbar {
    position: fixed;
    right: 18%;
    z-index: 1;

    @include to($max_width: 1440px) {
      right: 12%;
    }
    @include to($max_width: 480px) {
      right: 4%;
    }
    #navbar {
      @include navbar();
    }
  }
}

@media screen and (max-width: 768px) {
  .logo-subscribe {
      .logo-pos {
        .logo-part-1 {
          width: 31px;
          left: 17px;

        }
        .logo-animate {
          top: 25px;
          left: 31px;
          width: 17px;

        }
        .logo-part-618 {
          bottom: -85px;
          left: 32px;
          width: 17px;

        }
    }
  }
}

@media screen and (max-width: 480px) {
  .logo-subscribe {
    .logo-pos {
      left: 0;
      .logo-part-1 {
        width: 22px;
        left: 11px;

      }
      .logo-animate {
        top: 15px;
        left: 21px;
        width: 12px;

      }
      .logo-part-618 {
        bottom: -60px;
        left: 21px;
        width: 12px;

      }
    }


  }

  .subscribe {
    .subscribe-main {

      #group-input {
        .checkbox {
          input[type="checkbox"] + label:before {

            height: 12px;
            width: 12px;
            border: 1px solid #363636;
          }

          input[type="checkbox"] + label:after {
            height: 12px;
            width: 12px;
          }
        }

      }
      .subscribe-button {
        .button {
          border: 2px solid #363636;

        }
      }
    }
  }
}



