$text-color: #363636;

@mixin navbar() {
  position: relative;
  cursor: pointer;
  display: block;
  font-size: 25px;
  color: $text-color;
  font-weight: 300;
  line-height: 52px;

  @include to($max_width: 1280px) {
    font-size: 21px;
    line-height: 40px;
  }

  @include to($max_width: 768px) {
    font-size: 17px;
    line-height: 35px;
  }

  &:hover {
    color: #9E9E9E;
    transition: all 0.3s ease;
    @include to($max_width: 1024px) {
      color: $text-color;
    }
  }

  &:before {
    background-color: #363636;
    content: "";
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    left: -35%;
    top: 44%;
    @include to($max_width: 480px) {
      left: -15%;
    }
  }

  &:hover:before {
    background-color: #9E9E9E;
    transition: all 0.3s ease;
    @include to($max_width: 1024px) {
      background-color: $text-color;
    }


  }

}


@mixin nav-en-link() {
  display: block;
  position: relative;
  z-index: 2;
  font-size: 19px;
  line-height: 45px;
  color: #9E9E9E;

  @include to($max_width: 1280px) {
    line-height: 40px;
  }
  @include to($max_width: 768px) {
    font-size: 15px;
    line-height: 35px;
  }

  &:hover {
    color: #363636;
    transition: all 0.3s ease;
  }

  &:before {
    background-color: #9E9E9E;
    content: "";
    border-radius: 50%;
    width: 4px;
    height: 4px;
    position: absolute;
    display: inline-block;
    left: -35%;
    top: 44%;
    @include to($max_width: 480px) {
      left: -15%;
    }
  }
  &:hover:before {
    background: #363636;
    transition: all 0.3s ease;

  }
}


@mixin nav-active() {
  position: relative;
  font-weight: bold;
  pointer-events: none;

  &:before{
    position: absolute;
    background-color: #363636;
    content: "";
    border-radius: 50%;
    width: 8px;
    height: 8px;
    display: inline-block;
    top: 40%;
    left: -37%;

    @include to($max_width: 480px) {
      left: -16%;
      width: 6px;
      height: 6px;
    }
  }
}

@mixin counter(){
  color: $text-color;
  font-size: 21px;
  font-weight: bold;
  @include to($max_width: 480px) {
    font-size: 16px;
  }
}

@mixin nav-back() {
  position: relative;
  font-size: 25px;
  line-height: 26px;

  color: $text-color;
  font-weight: 300;
  &:hover {
    color: #9E9E9E;
    transition: all 0.1s ease;
    @include to($max_width: 1024px) {
      color: $text-color;
    }
  }
  &:hover:before {
    background-color: #9E9E9E;
    transition: all 0.3s ease;
    @include to($max_width: 1024px) {
      background-color: $text-color;
    }
  }

  &:before {
    position: absolute;
    top: 40%;
    left: -38%;
    background-color: #363636;
    content: "";
    border-radius: 80%;
    width: 4px;
    height: 4px;
    display: inline-block;
    @include to($max_width: 1920px) {
      left: -35%;
    }
    @include to($max_width: 1280px) {
      left: -45%;
    }
    @include to($max_width: 1024px) {
      left: -44%;
    }
    @include to($max_width: 480px) {
      left: -17%;
    }
  }
  @media screen and (max-width: 1280px) {
    font-size: 21px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;

  }
}


@mixin nav-next-prev () {
  position: relative;
  font-size: 25px;
  line-height: 26px;

  color: $text-color;
  font-weight: 300;
  &:hover {
    color: #9E9E9E;
    transition: all 0.1s ease;
  }
  &:hover:before {
    background-color: #9E9E9E;
    transition: all 0.3s ease;

  }

  &:before {
    position: absolute;
    top: 21%;
    left: -45%;
    background-color: #363636;
    content: "";
    border-radius: 80%;
    width: 4px;
    height: 4px;
    display: inline-block;
    @include to($max_width: 1440px) {
      left: -46%;
    }

    @include to($max_width: 1280px) {
      left: -58%;
    }
    @include to($max_width: 1024px) {
      left: -50%;
    }
    @include to($max_width: 768px) {
      left: -65%;
    }
    @include to($max_width: 480px) {
      left: -20%;
    }
    @include to($max_width: 380px) {
      left: -22%;
    }
  }
  @media screen and (max-width: 1280px) {
    font-size: 21px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;

  }
}