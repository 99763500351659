.team-page {

  .header {
    @include shift-left(0.9);
    padding-bottom: 2%;
    .row {
      @include row-flex();
      justify-content: space-between;

      .text-header {
        @include col();
        @include size(5);
        @include size-xs(7);

        h1 {
          @include header-text;
        }
        p {
          @include p-text;
        }
      }

    //   .navbar {
    //     opacity: 1;
    //     transition: all 0.3s ease-out;
    //     position: fixed;
    //     right: 18%;
    //     z-index: 1;

    //     @include to($max_width: 1440px) {
    //       right: 12%;
    //     }
    //     @include to($max_width: 480px) {
    //       right: 5%;
    //     }


    //     &.fade-out {
    //       opacity: 0;
    //     }
    //     .navigation {
    //       @include navbar();
    //     }
    //     .current-menu-item {
    //       @include nav-active()

    //     }
    //     .en-link {
    //       @include nav-en-link();
    //     }
    //     #navbar {
    //       opacity: 0;
    //       pointer-events:none;
    //       transition: all 0.3s ease-in;
    //     }

    //   }
    }
  }

  .team-photo {
    @include shift-left(0.9);
    @include size(10);
    @include size-xs(11);
    margin-bottom: 8vh;
    @include to($max-width: 768px) {
      margin-bottom: 8vh;
    }
    @include to($max-width: 480px) {
      margin-bottom: 5vh;
    }
  }

  //TODO: Team-photo
  .team-photo-items {
    @include size(7.4);
    @include size-xs(6.4);
    @include shift-left(0.9);
    .row:nth-child(odd), .row:nth-child(even) {
      margin-bottom: 0;
    }
    .row {
      @include row-flex;
      justify-content: space-between;

      .col {
        @include col();
        @include size(5.5);
        @include size-xs(12);
        position: relative;
        margin-bottom: 8vh;

        @include to($max-width: 768px) {
          margin-bottom: 6vh;
        }
        @include to($max-width: 480px) {
          margin-bottom: 5vh;
        }

        .text-about {
          position: absolute;
          bottom: 5%;
          left: 15%;
          transform: translate(-5%, -15%);

          .text-prof {
            @include photo-text-prof;
          }
          .text-name {
            @include photo-on-text;
          }
        }
      }
    }
  }

  //TODO: Article
  .team-text-inform {
    @include size(3);
    @include size-xxlg(4);
    @include size-xlg(4.5);
    @include size-lg(5);
    @include size-sm(6);
    @include size-xs(7);
    @include shift-left(0.9);
    margin-bottom: 5%;
    margin-top: 5%;

    .text-inform {
      h1 {
        @include header-text;
      }
      p {
        @include p-text;
      }
    }
  }

  .team-article {
    @include shift-left(0.9);
    @include size(7.4);
    @include size-xs(6.4);
    margin-bottom: 10%;

    .row {
      @include row-flex;
      justify-content: space-between;

      .col {
        @include col();
        @include size(5.5);
        @include size-xs(12);
        .item {
          @include border-bottom-text;
          margin-bottom: 8vh;
          @include to($max-width: 768px) {
            margin-bottom: 6vh;
          }
          @include to($max-width: 480px) {
            margin-bottom: 5vh;
          }
          h2 {
            position: relative;
            @include title-h2;

            padding-bottom: 5%;
          }
          h2:before {
            @include pseudo-dot-h2;
          }
          p {
            @include p-text;
            padding-bottom: 3%;
          }
        }
      }
    }
    //TODO: Button
    .team-button {
      @include size(5.5);
      @include size-xs(12);

      .button {
        @include button();
        display: inline-block;
        text-align: center;
      }
    }
  }
}
