@mixin navbar-footer() {
  position: relative;
  display: block;
  cursor: pointer;

  color: #fff;
  font-size: 25px;
  font-weight: 300;
  line-height: 60px;

  @include to($max_width: 1280px) {
    font-size: 21px;
    line-height: 40px;
  }

  @include to($max_width: 768px) {
    font-size: 17px;
    line-height: 35px;
  }
  &:hover {
    color: #9e9e9e;
    transition: all .3s ease;
  }

  &:before {
    position: absolute;
    background-color: #fff;
    content: "";
    border-radius: 50%;
    width: 4px;
    height: 4px;
    display: inline-block;
    left: -35%;
    top: 44%;
    @include to($max_width: 480px) {
      left: -15%;
    }
  }
  &:hover:before {
    background-color: #9e9e9e;
    transition: all .3s ease;
  }
}

@mixin en-link {
  display: block;
  position: relative;
  font-size: 19px;
  line-height: 45px;
  color: #fff;

  @include to($max_width: 1280px) {
    line-height: 40px;
  }
  @include to($max_width: 768px) {
    font-size: 15px;
    line-height: 35px;
  }

  &:hover {
    color: #9e9e9e;
    transition: all .3s ease;
  }
  &:before {
    position: absolute;
    background-color: #fff;
    content: "";
    border-radius: 50%;
    width: 4px;
    height: 4px;
    display: inline-block;
    left: -35%;
    top: 44%;
    @include to($max_width: 480px) {
      left: -15%;
    }
  }

  &:hover:before {
    background-color: #9e9e9e;
    transition: all .3s ease;
  }
}




@mixin nav-footer-active(){
  position: relative;
  font-weight: bold;
  pointer-events: none;

  &:before{
    position: absolute;
    background-color: #fff;
    content: "";
    border-radius: 50%;
    width: 8px;
    height: 8px;
    display: inline-block;
    top: 40%;
    left: -37%;

    @include to($max_width: 480px) {
      left: -16%;
      width: 6px;
      height: 6px;
    }
  }
}

